import React from 'react';
import { Link } from 'gatsby';
import { Layout } from '../../../components/Layout';

const PropertyLineSurveys = () => (
  <Layout
    title="Property & Fence Line Surveys Calgary & Red Deer | Axiom Geomatics"
    description="A Property Line Survey accurately identifies where property lines are, usually they are clearly marked on the ground with orange construction stakes."
    keywords="property line survey, fence line survey, property line stakeout, fence line stakeout, markup property line, find a property line"
  >
    <main>
      <h1>Property & Fence Line Surveys Calgary</h1>
      <p>
        Whether in an urban or rural setting, knowledge of where property boundaries actually are is
        critical. A <strong>property line survey</strong> accurately identifies where the borders of
        the property are located.
      </p>

      <p>
        Axiom Geomatics conducts property line surveys and "stakes-out" the border/boundary - we
        clearly mark on the ground the actual property line with orange construction stakes.
      </p>

      <p>Common mistakes made when the actual location of a property line is not known:</p>

      <ul>
        <li>Municipal set-back requirements cannot be met</li>
        <li>
          Construction of a feature (e.g. retaining wall, fence, building) on another's property
        </li>
        <li>Building within right-of-ways</li>
        <li>Lawsuits and/or destruction of the offending feature</li>
      </ul>

      <h2>Property Line Survey Cost in Calgary</h2>
      <p>
        A considerable amount of detailed work is involved in accurately determining where property
        lines lie. The size of the lot, natural features, shape and availability of survey evidence
        directly impact the time it takes to complete the project.
      </p>

      <p>
        A typical property in the City of Calgary takes at least 3 hours to complete. Large rural
        properties may require a couple of days. Work is billed hourly given the unknowns of each
        situation.
      </p>

      <p>
        By legislation, only an{' '}
        <strong>
          Alberta Land Surveyor is able to legally able to identify property boundaries
        </strong>
        ; ensure your markings are legally sound before beginning construction.
      </p>

      <h2>
        <abbr title="Frequently Asked Questions">Property & Fence Line Survey FAQs</abbr>
      </h2>

      <ul>
        <li>
          <Link to="#own-to-the-road">Don't I own up-to the road on my property?</Link>
        </li>
        <li>
          <Link to="#fence-style">What style can my fence be?</Link>
        </li>
        <li>
          <Link to="#fence-height">How tall can my fence be?</Link>
        </li>
        <li>
          <Link to="#fence-on-line">Does a fence need to be built on the property line?</Link>
        </li>
        <li>
          <Link to="#corner-lot">
            I own a corner lot, why can't I build to the edge of my property?
          </Link>
        </li>
      </ul>

      <h3 id="own-to-the-road">Don't I own up-to the road on my property?</h3>
      <p>
        Probably not. The property line can be anywhere from the sidewalk to many meters onto what
        you perceive as your front yard. Additionally even inside that there may be utility
        right-of-way. In most municipalities you are, however, responsible for the care and
        maintenance of that property (e.g. snow shovelling, lawn care, etc…).
      </p>

      <h3 id="fence-style">What style can my fence be?</h3>
      <p>
        In most neighbourhoods it can be any style you like. In some neighbourhoods this is limited
        by agreements on title, commonly this limits you to a 4-foot high, black, chain-link fence.
      </p>

      <h3 id="fence-height">How tall can my fence be?</h3>
      <p>
        Fence height is typically limited by the municipality (via bylaws) and possibly via
        neighbourhood-specific architectural controls. It is the responsibility of the property
        owner to adhere to all local rules and regulations.
      </p>

      <h3 id="fence-on-line">Does a fence need to be built on the property line?</h3>
      <p>
        No, you can build a fence on your side of the property line (especially if you disagree with
        your neighbour) or directly on the line if your neighbour agrees to the construction. Of
        course, it is always recommended to work with your neighbour where possible. An exception to
        this is if you are building along a utility right-of-way, in this case you MUST build on the
        property line.
      </p>

      <h3 id="corner-lot">I own a corner lot, why can't I build to the edge of my property?</h3>
      <p>
        Corner lots bordered by roads cannot have features (fences, buildings, etc…) within a
        certain distance of the corner so that visibility for vehicles is not obstructed. This
        distance varies by municipality.
      </p>
    </main>
  </Layout>
);

export default PropertyLineSurveys;
